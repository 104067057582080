import DrawerNotificationButtonGroups from '@/components/Drawer/DrawNotificationButtonGroups'

interface Props {
  testIdPrimary: string
  testIdTertiary?: string
  primaryButtonLabel: string
  tertiaryButtonLabel?: string
  onClickPrimary: () => void
  onClickTertiary?: () => void
  isPrimaryDisabled?: boolean
  isTertiaryDisabled?: boolean
}

export default function WhatsAppTemplateButtonGroup({
  testIdTertiary,
  testIdPrimary,
  onClickPrimary,
  onClickTertiary,
  primaryButtonLabel,
  tertiaryButtonLabel,
  isPrimaryDisabled,
}: Props) {
  return (
    <>
      <DrawerNotificationButtonGroups
        hasTertiaryButton={true}
        testIdPrimary={testIdPrimary}
        testIdTertiary={testIdTertiary}
        primaryButtonLabel={primaryButtonLabel}
        tertiaryButtonLabel={tertiaryButtonLabel}
        onClickPrimary={onClickPrimary}
        onClickTertiary={onClickTertiary}
        isPrimaryDisabled={isPrimaryDisabled}
      />
    </>
  )
}
