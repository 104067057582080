import WhatsAppTemplateCreationDrawer from '@/components/Drawer/WhatsAppTemplateCreationDrawer'
import WhatsappTemplateTable from '@/components/MessageTemplateTable/WhatsappTemplateTable'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useParams} from 'react-router-dom'
import {PrimaryButton} from '../shared-components/Button'
import {PageHeader} from '../shared-components/PageHeader'
import {PageContent} from './Template/components/PageContent'

export default function MessageTemplatesList(): React.JSX.Element {
  const {formatMessage} = useIntl()
  const onClickCreate = () => {
    setIsOpen(true)
  }
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onSetIsOpen = (value: boolean) => {
    setIsOpen(value)
  }

  const {id: whatsAppTemplateId} = useParams()
  const {pathname} = useLocation()
  const isEditing = pathname?.includes('/edit')
  const isEventDetailsOpen = !!whatsAppTemplateId || isEditing

  return (
    <PageContent data-testid="message-template-list-page">
      <div className="mb-6">
        <PageHeader title={formatMessage({id: 'MESSAGE_TEMPLATE_LIST.TABLE_HEADER'})} />
        <div className="mt-2 flex items-start gap-4 text-base" data-testid="subtitle-content">
          <div className="subtitle flex-1 text-sm text-text-secondary">
            {formatMessage({id: 'MESSAGE_TEMPLATE_LIST.TABLE_HEADER_SUBTITLE'})}
          </div>
          <div className="button-container ml-auto flex gap-4">
            <PrimaryButton
              iconTrailing="playlist_add"
              size="small"
              data-cy="create-whatsapp-template-button"
              data-testid="create-whatsapp-template-button"
              onClick={onClickCreate}
            >
              {formatMessage({id: 'MESSAGE_TEMPLATE_LIST.CREATE_TEMPLATE_BUTTON'})}
            </PrimaryButton>
          </div>
        </div>
      </div>

      <WhatsappTemplateTable />
      <WhatsAppTemplateCreationDrawer
        isOpen={isOpen || isEventDetailsOpen}
        setIsOpen={onSetIsOpen}
        isTemplateDetailPage={!!whatsAppTemplateId}
      />
    </PageContent>
  )
}
