interface Props {
  templateMessage: string
}

export default function WhatsAppTemplateMobilePreview({templateMessage}: Props): React.JSX.Element {
  return (
    <>
      <div className="relative h-[600px] w-[300px]">
        <img src="/img/whatsapp-template/whatsapp-preview.png" alt="Preview Page" />
      </div>
      <div className="bg absolute left-[50px] top-[250px] z-10 h-[220px] w-[220px] overflow-hidden text-ellipsis bg-[#f9f9f9] pl-2 text-sm text-black">
        {templateMessage}
      </div>
    </>
  )
}
