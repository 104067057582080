export const useGetWhatsAppTemplateDetails = (whatsappTemplateId?: string) => {
  // This is a placeholder function to simulate fetching WhatsApp template details

  const data = {
    templateId: whatsappTemplateId,
    templateName: 'Template Name 1',
    category: 'OTP',
    templateMessage: 'Template Message placeholder for testing purposes',
    templateStatus: 'Draft',
  }

  return {
    data,
    isFetching: false,
    error: null,
  }
}
