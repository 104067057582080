import {
  notificationsDetailsPropsMapper,
  useGetEventNotificationDetails,
} from '@/components/Drawer/hooks/useGetEventNotificationDetails'
import NotificationDetails from '@/components/Drawer/NotificationDetails'
import NotificationSmsDetails from '@/components/Drawer/NotificationSmsDetails'
import NotificationSubject from '@/components/Drawer/NotificationSubject'
import NotificationWhatsAppDetails from '@/components/Drawer/NotificationWhatsAppDetails'
import {ROUTES} from '@/config/constants'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {isSendPushStep, isSendSmsStep, isSendWhatsAppStep} from '@/helpers/rtnNotificationType'
import {cn} from '@/helpers/shadcn/utils'
import {PrimaryButton} from '@/shared-components/Button'
import {EventStatus, EventStatusIds} from '@/types/Notifications'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

export interface EventNotificationDetailsProps {
  readonly notificationId: string
}

export default function EventNotificationDetails({notificationId}: EventNotificationDetailsProps): React.JSX.Element {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()

  const {isFetching, data, error} = useGetEventNotificationDetails(notificationId)

  const notificationDetailsProps = notificationsDetailsPropsMapper(data?.data)

  const notificationType = notificationDetailsProps?.notificationType ?? ''

  const smsMessage =
    isSendSmsStep(notificationType) && notificationDetailsProps.smsMessage ? notificationDetailsProps.smsMessage : ''
  const whatsAppMessage =
    isSendWhatsAppStep(notificationType) && notificationDetailsProps.whatsAppMessage
      ? notificationDetailsProps.whatsAppMessage
      : ''

  const onEditDidClick = () => {
    navigate(`${ROUTES.EVENT_NOTIFICATION_EDIT.replace(':id', notificationId)}`)
  }

  return (
    <div className="-mt-1 overflow-auto px-1" data-testid="event-notification-details-container">
      <div className="mb-4 text-lg font-bold text-text-primary">
        {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_HEADER'})}
      </div>
      {isFetching && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-loading">
          {formatMessage({id: 'NOTIFICATION_DETAILS.LOADING_DETAILS_LABEL'})}
        </p>
      )}
      {!isFetching && error && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-error">
          {formatMessage({id: 'NOTIFICATION_DETAILS.ERROR_DETAILS_LABEL'})}
        </p>
      )}
      {!isFetching && !error && (
        <div data-testid="notification-details">
          <NotificationDetails {...notificationDetailsProps} />
          <div
            className={cn(
              'mt-4 text-text-primary',
              isFeatureEnabled(FeatureToggle.realTimeNotificationEditDetails) ? 'pb-16' : ''
            )}
          >
            {isSendPushStep(notificationType) && (
              <>
                <div className="text-base font-bold">
                  {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SECTION_TITLE'})}
                </div>
                <NotificationSubject />
              </>
            )}
            {isSendSmsStep(notificationType) && (
              <NotificationSmsDetails
                notificationTypeName={formatMessage({id: 'JOURNEY_BUILDER.SEND_SMS_WIDGET_LABEL'})}
                smsMessage={smsMessage}
              />
            )}
            {isSendWhatsAppStep(notificationType) && (
              <NotificationWhatsAppDetails
                notificationTypeName={formatMessage({id: 'JOURNEY_BUILDER.SEND_WHATSAPP_WIDGET_LABEL'})}
                whatsAppMessage={whatsAppMessage}
              />
            )}
          </div>
          <NotificationDetailsActions
            onEditDidClick={onEditDidClick}
            eventStatus={notificationDetailsProps.eventStatus}
          />
        </div>
      )}
    </div>
  )
}

type NotificationDetailsActionsProps = {
  onEditDidClick?: () => void
  eventStatus?: EventStatus
}

const NotificationDetailsActions = ({onEditDidClick, eventStatus}: NotificationDetailsActionsProps) => {
  const {formatMessage} = useIntl()

  if (!isFeatureEnabled(FeatureToggle.realTimeNotificationEditDetails)) {
    return null
  }

  const isEditDisabled = eventStatus?.id_eventstatus === EventStatusIds.Published

  return (
    <div className="absolute bottom-0 right-0 flex h-16 w-full items-center justify-end gap-4 bg-layer-01 px-6 py-4 shadow-3xl">
      {/*
      // Future scope! Left it here just for reference
      <SecondaryButton id="notification-details-pause">
        {formatMessage({id: 'NOTIFICATION_DETAILS.PAUSE_BUTTON'})}
      </SecondaryButton>
      */}
      <PrimaryButton id="notification-details-edit" onClick={onEditDidClick} disabled={isEditDisabled}>
        {formatMessage({id: 'NOTIFICATION_DETAILS.EDIT_BUTTON'})}
      </PrimaryButton>
    </div>
  )
}
