import {STEP_CONFIG} from '@/config/constants'
import {cn} from '@/helpers/shadcn/utils'
import {useCountries} from '@/hooks/useCountries'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import {Spinner} from '@/shared-components/Spinner'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {GetSendWhatsAppStepConfigResponse} from '@/types/StepConfigParams'
import {getStepConfigUseCase} from '@/useCases/getStepConfigUseCase'
import {useQuery} from '@tanstack/react-query'
import React from 'react'
import {useIntl} from 'react-intl'

interface Props {
  selectedTemplateId?: string
  setSelectedTemplateId: (value: string) => void
  whatsAppMessage?: string
  setWhatsAppMessage: (value: string) => void
}

export default function SendWhatsAppParams({
  selectedTemplateId,
  setSelectedTemplateId,
  // whatsAppMessage,
  setWhatsAppMessage,
}: Props): React.JSX.Element {
  const {country} = useCountries()
  const {isFetching, error, data} = useQuery({
    queryKey: [STEP_CONFIG.DATA_QUERY_KEY, NodeTypeEnum.sendWhatsApp, country],
    queryFn: async ({signal}: {signal: AbortSignal}) => {
      const result = await getStepConfigUseCase(NodeTypeEnum.sendWhatsApp, signal)
      return (result as GetSendWhatsAppStepConfigResponse) ?? {}
    },
    staleTime: 3 * 60 * 1000, // 3 mins
  })
  const {formatMessage} = useIntl()

  const templateNotSelectedError = !selectedTemplateId
    ? formatMessage({id: 'SEND_NOTIFICATION_CONTENT.NO_TEMPLATE_SELECTED'})
    : undefined

  return (
    <div className="flex max-w-[678px] flex-col gap-4" data-testid="send-whatsapp-params">
      {isFetching && <Spinner size="medium" data-testid="whatsapp-template-loading" />}
      {!isFetching && error && (
        <p className="text-md my-16 items-center text-center" data-testid="whatsapp-template-error">
          {formatMessage({id: 'NOTIFICATION_DETAILS.TEMPLATE_LOADING_ERROR'})}
        </p>
      )}

      {!isFetching && !error && (
        <>
          <InfoOrError error={templateNotSelectedError} id="no-template-selected" />
          <div className="flex flex-wrap gap-4">
            {data?.templates?.map(templateItem => {
              const {id, message} = templateItem
              const isSelected = id === selectedTemplateId

              return (
                <div
                  key={id}
                  data-testid="template-message-container"
                  data-cy="template-message-container"
                  className={cn(
                    'message-box flex w-full max-w-xs grow bg-layer-01 data-[disabled=false]:cursor-pointer data-[disabled=false]:hover:bg-layer-01-hover',
                    {isSelected}
                  )}
                  onClick={() => {
                    setSelectedTemplateId(id)
                    setWhatsAppMessage(message)
                  }}
                >
                  <p className="text-left text-sm leading-6 text-text-secondary">{message}</p>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
