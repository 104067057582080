import DrawerNotificationButtonGroups from '@/components/Drawer/DrawNotificationButtonGroups'
import NotificationDetails, {NotificationDetailsProps} from '@/components/Drawer/NotificationDetails'
import NotificationSmsDetails from '@/components/Drawer/NotificationSmsDetails'
import NotificationSubject from '@/components/Drawer/NotificationSubject'
import NotificationWhatsAppDetails from '@/components/Drawer/NotificationWhatsAppDetails'
import {isSendPushStep, isSendSmsStep, isSendWhatsAppStep} from '@/helpers/rtnNotificationType'
import {NotificationDrawerStatusEnum} from '@/types/NotificationDrawerStatusEnum'
import {useIntl} from 'react-intl'

interface Props extends NotificationDetailsProps {
  updateDrawStatus: (status: NotificationDrawerStatusEnum) => void
  closeDrawer: () => void
  saveAsDraftAndCloseDrawer: () => void
  publishNotification: () => void
}

export default function NotificationDetailsPreview({
  notificationName,
  notificationDescription,
  country,
  eventSelectors,
  paramSelectors,
  period,
  timeExecutionWindow,
  fallsOnBankHoliday,
  saveAsDraftAndCloseDrawer,
  publishNotification,
  notificationType = '',
  smsMessage = '',
  whatsAppMessage = '',
  updateDrawStatus,
}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  const goBackToCreateNotification = function () {
    updateDrawStatus(NotificationDrawerStatusEnum.createNotification)
  }

  return (
    <>
      <div className="col-span-4 mb-20 max-w-[678px]">
        <div className="text-sm text-text-secondary" data-testid="preview-drawer">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_PREVIEW_HEADER'})}
        </div>
        {isSendPushStep(notificationType) ? <NotificationSubject /> : null}
        <NotificationDetails
          notificationName={notificationName}
          notificationDescription={notificationDescription}
          country={country}
          eventSelectors={eventSelectors}
          paramSelectors={paramSelectors}
          period={period}
          timeExecutionWindow={timeExecutionWindow}
          fallsOnBankHoliday={fallsOnBankHoliday}
        />
        {isSendSmsStep(notificationType) ? (
          <NotificationSmsDetails
            notificationTypeName={formatMessage({id: 'JOURNEY_BUILDER.SEND_SMS_WIDGET_LABEL'})}
            smsMessage={smsMessage}
          />
        ) : null}
        {isSendWhatsAppStep(notificationType) ? (
          <NotificationWhatsAppDetails
            whatsAppMessage={whatsAppMessage}
            notificationTypeName={formatMessage({id: 'JOURNEY_BUILDER.SEND_WHATSAPP_WIDGET_LABEL'})}
          />
        ) : null}
      </div>
      <DrawerNotificationButtonGroups
        hasSecondaryButton={true}
        hasTertiaryButton={true}
        testIdPrimary="publish-button"
        testIdSecondary="save-as-draft-button"
        testIdTertiary="cancel-button"
        onClickPrimary={publishNotification}
        onClickSecondary={saveAsDraftAndCloseDrawer}
        onClickTertiary={goBackToCreateNotification}
        primaryButtonLabel={formatMessage({
          id: 'NOTIFICATION_BUILDER.PUBLISH_BUTTON',
        })}
        secondaryButtonLabel={formatMessage({
          id: 'NOTIFICATION_BUILDER.SAVE_AS_DRAFT_BUTTON',
        })}
        tertiaryButtonLabel={formatMessage({
          id: 'NOTIFICATION_BUILDER.CANCEL_BUTTON',
        })}
        isPrimaryDisabled={false}
        isSecondaryDisabled={false}
      />
    </>
  )
}
