import {AutoComplete} from '@/components/SendNotification/AutoComplete'
import {MAX_MESSAGE_LENGTH} from '@/utils/consts'
import React from 'react'
import {useIntl} from 'react-intl'

interface Props {
  setSmsMessage: (value: string) => void
  smsMessage: string
}

export default function SendSmsParams({setSmsMessage, smsMessage}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  return (
    <div className="h-42 flex w-full max-w-xs flex-col gap-3" data-testid="send-sms-params">
      <AutoComplete
        label={formatMessage({id: 'NOTIFICATION_BUILDER.SUBJECT_LABEL'})}
        inputId="notification-subject-sms"
        inputValue={smsMessage}
        onChangeInput={setSmsMessage}
        max_message_length={MAX_MESSAGE_LENGTH}
        required
      />
    </div>
  )
}
