import CreateNotificationPage from '@/components/Drawer/CreateNotificationPage'
import {Drawer} from '@/components/Drawer/Drawer'
import NotificationDetailsPreview from '@/components/Drawer/NotificationDetailsPreview'
import {
  notificationsDetailsPropsMapper,
  useGetEventNotificationDetails,
} from '@/components/Drawer/hooks/useGetEventNotificationDetails'
import {defaultEventValue} from '@/components/NotificationParamsFilterCard/NotificationEventDefaultValue'
import {countryCodeToId, countryIdToName} from '@/config/constants'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {Spinner} from '@/shared-components/Spinner'
import {store} from '@/store'
import {showNotification} from '@/store/slices'
import {NotificationDrawerStatusEnum} from '@/types/NotificationDrawerStatusEnum'
import {Period} from '@/types/Period'
import {TimeExecutionWindowNotification} from '@/types/TimeExecution'
import {ParameterItem, Parameters} from '@/types/Traits'
import {createNotificationUseCase} from '@/useCases/createNotificationUseCase'
import {getAllTraitsEventUseCase, getAllTraitsParameterUseCase} from '@/useCases/getAllTraitsUseCase'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import _, {omitBy} from 'lodash'
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {createNotificationRequestBody} from './createNotificationData'

interface Props {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export default function NotificationDrawer({isOpen, setIsOpen}: Props): React.JSX.Element {
  const queryClient = useQueryClient()
  const {formatMessage} = useIntl()
  const {country} = useJourneyMetaConfig()
  const countryId = countryCodeToId[country]
  const countryName = countryIdToName[countryId]
  const title = formatMessage({id: 'NOTIFICATION_BUILDER.CREATE_NOTIFICATION_HEADER'})
  const [drawerStatus, setDrawerStatus] = useState<NotificationDrawerStatusEnum>(
    NotificationDrawerStatusEnum.createNotification
  )
  const [notificationName, setNotificationName] = useState<string>()
  const [notificationDescription, setNotificationDescription] = useState<string>()
  const [eventSelectors, setEventSelectors] = useState<ParameterItem>(defaultEventValue)
  const [paramSelectors, setParamSelectors] = useState<Parameters>([])
  const [period, setPeriod] = useState<Period>()
  const [timeExecutionWindow, setTimeExecutionWindow] = useState<TimeExecutionWindowNotification>()
  const [fallsOnBankHoliday, setFallsOnBankHoliday] = useState<string>()

  const [notificationType, setNotificationType] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [message, setMessage] = useState<string>()
  const [smsMessage, setSmsMessage] = useState<string>()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
  const [whatsAppMessage, setWhatsAppMessage] = useState<string>()

  const {
    isFetching: isFetchingEvent,
    data: dataEvents,
    error: errorEvents,
  } = useQuery({
    queryKey: ['traits', ['getAllTraits', 'events']],
    queryFn: ({signal}) => getAllTraitsEventUseCase({traitType: 'events'}, signal),
  })

  const events = dataEvents?.data?.traits ?? []
  const eventOperators = dataEvents?.data?.operators ?? {}

  const {
    isFetching: isFetchingTraits,
    data: dataTraits,
    error: errorTraits,
  } = useQuery({
    queryKey: ['traits', ['getAllTraits', 'event-order-parameter']],
    queryFn: ({signal}) => getAllTraitsParameterUseCase({traitType: 'event-order-parameter'}, signal),
  })

  const traits = dataTraits?.data?.traits ?? []
  const operators = dataTraits?.data?.operators ?? {}

  const {id: notificationId} = useParams()

  const {
    isFetching: isFetchingDetails,
    data: dataDetails,
    error: errorDetails,
  } = useGetEventNotificationDetails(notificationId)

  const cleanUpAllFields = function (): void {
    setNotificationName(undefined)
    setNotificationDescription(undefined)
    setEventSelectors(defaultEventValue)
    setParamSelectors([])
    setPeriod(undefined)
    setTimeExecutionWindow(undefined)
    setFallsOnBankHoliday(undefined)
    setNotificationType(undefined)
    setSubject(undefined)
    setMessage(undefined)
    setSmsMessage(undefined)
    setSelectedTemplateId(undefined)
    setWhatsAppMessage(undefined)
  }

  const closeDrawer = function (): void {
    cleanUpAllFields()
    setDrawerStatus(NotificationDrawerStatusEnum.createNotification)
    setIsOpen(false)
  }

  const {mutate} = useMutation({
    mutationKey: ['createNotification'],
    mutationFn: createNotificationUseCase,
    onSuccess: () => {
      store.dispatch(
        showNotification({
          show: true,
          type: 'success',
          title: 'Success',
          message: 'Notification has been created successfully',
        })
      )
      queryClient.refetchQueries({queryKey: ['notifications']})
    },
    onError: error => {
      store.dispatch(
        showNotification({
          show: true,
          type: 'error',
          title: 'Something went wrong!',
          message: `Error in creating notification: ${error}`,
        })
      )
      queryClient.refetchQueries({queryKey: ['notifications']})
    },
  })

  const saveAsDraftAndCloseDrawer = async () => {
    await persistData(1)
    closeDrawer()
  }

  const publishNotification = async () => {
    await persistData(2)
    closeDrawer()
  }

  const persistData = async (notificationStatus: number) => {
    const notificationConfigData = createNotificationRequestBody(
      countryId,
      notificationStatus,
      notificationName ?? '',
      notificationDescription ?? '',
      eventSelectors,
      paramSelectors,
      subject ?? '',
      message ?? ''
    )

    return mutate({
      notificationId,
      ...notificationConfigData,
    })
  }

  const drawStatusProvider = function (drawerStatus: NotificationDrawerStatusEnum): React.JSX.Element {
    const notificationDetailsPersistedData = notificationsDetailsPropsMapper(dataDetails?.data)

    const commonProps = {
      ...notificationDetailsPersistedData,
      ..._.omitBy(
        {
          notificationName,
          notificationDescription,
          notificationType,
          fallsOnBankHoliday,
          subject,
          message,
          smsMessage,
          selectedTemplateId,
          whatsAppMessage,
        },
        _.isUndefined
      ),
      ..._.pickBy(
        {
          period,
          timeExecutionWindow,
        },
        _.isObject
      ),
      ...omitBy(
        {
          paramSelectors: _.isEqual(paramSelectors, []) ? undefined : paramSelectors,
          eventSelectors: _.isEqual(eventSelectors, defaultEventValue) ? undefined : eventSelectors,
        },
        _.isUndefined
      ),
      country: countryName,
    }

    const updateFieldsProps = {
      setNotificationName,
      setNotificationDescription,
      setNotificationType,
      setSubject,
      setMessage,
      setSmsMessage,
      setSelectedTemplateId,
      setWhatsAppMessage,
      setPeriod,
      setTimeExecutionWindow,
      setFallsOnBankHoliday,
      setEventSelectors,
      setParamSelectors,
    }
    switch (drawerStatus) {
      case NotificationDrawerStatusEnum.createNotification:
        return (
          <CreateNotificationPage
            {...commonProps}
            {...updateFieldsProps}
            isRemoteFetch={true}
            events={events}
            eventOperators={eventOperators}
            traits={traits}
            operators={operators}
            updateDrawStatus={(status: NotificationDrawerStatusEnum) => setDrawerStatus(status)}
            closeDrawer={closeDrawer}
            saveAsDraftAndCloseDrawer={saveAsDraftAndCloseDrawer}
          />
        )
      case NotificationDrawerStatusEnum.preview:
        return (
          <NotificationDetailsPreview
            {...commonProps}
            updateDrawStatus={(status: NotificationDrawerStatusEnum) => setDrawerStatus(status)}
            closeDrawer={closeDrawer}
            saveAsDraftAndCloseDrawer={saveAsDraftAndCloseDrawer}
            publishNotification={publishNotification}
          />
        )
    }
  }
  const drawerContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (drawerContentRef.current) {
      drawerContentRef.current.scrollTop = 0
    }
  }, [drawerStatus])

  const isLoading = isFetchingEvent || isFetchingTraits || isFetchingDetails
  const hasError = errorEvents || errorTraits || errorDetails

  return (
    <Drawer headerTitle={title} isOpen={isOpen} onClose={closeDrawer}>
      <div
        ref={drawerContentRef}
        className="-mt-5 grid grid-cols-12 overflow-auto px-1"
        data-testid="create-notification-page-container"
      >
        <div className="col-span-12 mb-4 text-lg font-bold text-text-primary">
          {formatMessage({id: 'NOTIFICATION_BUILDER.CREATE_NOTIFICATION_HEADER'})}
        </div>
        {isLoading && <Spinner data-testid="create-notification-spinner" />}
        {!isLoading && hasError && (
          <p className="text-md col-span-6 my-16 items-center" data-testid="create-notification-error">
            {formatMessage({id: 'NOTIFICATION_BUILDER.ERROR_DETAILS_LABEL'})}
          </p>
        )}
        {!isLoading && !hasError && drawStatusProvider(drawerStatus)}
      </div>
    </Drawer>
  )
}
