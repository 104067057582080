import {CountrySelector} from '@/components/JourneyEditor/components/CountrySelector'
import {useCountries} from '@/hooks/useCountries'
import MessageTemplatesList from '@/pages/MessageTemplatesList'
import {useAppDispatch} from '@/store'
import {JourneyStatus} from '@/types/JourneyStatus'
import {setJourneyMeta} from '@ReduxActions'
import {useIntl} from 'react-intl'

export default function MessageTemplates(): React.JSX.Element {
  const {formatMessage} = useIntl()
  const {countries, country} = useCountries()
  const dispatch = useAppDispatch()

  const onCountryDidChange = (value: string) => {
    dispatch(setJourneyMeta({country: value}))
  }
  return (
    <div className="mt-5 grid gap-4" data-testid="message-templates-container">
      <div className="flex items-center justify-between">
        <p className="text-3xl font-bold">
          {formatMessage(
            {id: 'MESSAGE_TEMPLATE_LIST.WELCOME_MESSAGE'},
            {
              userName: 'User',
            }
          )}
        </p>
        <div className="right-0 flex flex-row items-center gap-2 text-xs font-normal">
          <p className="text-nowrap">{formatMessage({id: 'GENERAL.SELECT_MARKET'})}</p>
          <CountrySelector
            id="main-country"
            status={JourneyStatus.draft}
            countries={countries}
            value={country}
            onValueChanged={onCountryDidChange}
          />
        </div>
      </div>
      <MessageTemplatesList />
    </div>
  )
}
