import {getWhatsAppTemplateColumn} from '@/components/MessageTemplateTable/WhatsappTemplateColumn'
import {ROUTES} from '@/config/constants'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {DataTable} from '@/shared-components/DataTable'
import {SortingState} from '@tanstack/react-table'
import {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

type RequestParams = {
  idCountry: string
  pageNumber: number
  page: number
  pageSize: number
  sortBy?: string
}

export default function WhatsappTemplateTable(): React.JSX.Element {
  const {formatMessage} = useIntl()
  const {country} = useJourneyMetaConfig()
  const navigate = useNavigate()

  const initialRequestParams = useMemo(() => {
    return {
      idCountry: country,
      pageNumber: 0,
      page: 0,
      pageSize: 10,
      sortBy: 'name,asc',
    }
  }, [country])

  const [requestParams] = useState<RequestParams>(initialRequestParams)
  const [sortingColumn] = useState<SortingState>([])

  // setRequestParams({...requestParams, pageNumber: 1})

  const onWhatsAppTemplateNameClick = (templateId: string) => {
    navigate(`${ROUTES.MESSAGE_TEMPLATE.replace(':id', templateId)}`)
  }

  const data = {
    content: [
      {
        id: '1',
        name: 'Template 1',
        category: 'Category 1',
        status: 'Draft',
        updatedOn: '2024-08-12T00:00:00Z',
        createdBy: 'User 1',
        templateUsage: true,
      },
      {
        id: '2',
        name: 'Template 2',
        category: 'Category 2',
        status: 'Draft',
        updatedOn: '2024-08-12T00:00:00Z',
        createdBy: 'User 2',
        templateUsage: false,
      },
      {
        id: '3',
        name: 'Template 3',
        category: 'Category 3',
        status: 'Approved',
        updatedOn: '2024-08-15T00:00:00Z',
        createdBy: 'User Test',
        templateUsage: false,
      },
    ],
    totalPage: 3,
  }

  return (
    <div data-testid="whatsapp-template-table-container">
      {
        <DataTable
          columns={getWhatsAppTemplateColumn(formatMessage, onWhatsAppTemplateNameClick)}
          data={data?.content ?? []}
          pagination={{pageIndex: requestParams.pageNumber, pageSize: requestParams.pageSize}}
          pageCount={3}
          onPaginationDidChange={() => {}}
          sortingColumn={sortingColumn}
          onSortingDidChange={() => {}}
          emptyMessage={formatMessage({id: 'WHATSAPP_TEMPLATE_LIST.NO_WHASAPP_TEMPLATES_FOUND'})}
          data-testid="whatsapp-template-table"
        />
      }
    </div>
  )
}
