import WhatsAppTemplateButtonGroup from '@/components/Drawer/WhatsAppTemplateButtonGroup'
import WhatsAppTemplateMobilePreview from '@/components/Drawer/WhatsAppTemplateMobilePreview'
import {useIntl} from 'react-intl'

interface Props {
  templateName: string
  category: string
  templateMessage: string
  sendWhatsAppTemplateDraft: () => void
  sendWhatsAppTemplate: () => void
}

export default function WhatsAppTemplatePreview({
  templateName,
  templateMessage,
  sendWhatsAppTemplateDraft,
  sendWhatsAppTemplate,
}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  return (
    <div data-testid="preview-page-container" className="ml-4 flex w-full flex-col items-start">
      <div className="mb-4 text-lg font-bold text-text-primary">{templateName}</div>
      <WhatsAppTemplateMobilePreview templateMessage={templateMessage} />
      <WhatsAppTemplateButtonGroup
        testIdTertiary="whatsapp-template-send-as-draft"
        testIdPrimary="whatsapp-template-send"
        onClickPrimary={sendWhatsAppTemplate}
        onClickTertiary={sendWhatsAppTemplateDraft}
        primaryButtonLabel={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.SEND_BUTTON_LABEL'})}
        tertiaryButtonLabel={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.SAVE_AS_DRAFT_BUTTON_LABEL'})}
      />
    </div>
  )
}
