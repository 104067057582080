import {useIntl} from 'react-intl'

interface Props {
  notificationTypeName: string
  whatsAppMessage: string
}

export default function NotificationWhatsAppDetails({notificationTypeName, whatsAppMessage}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()

  return (
    <div data-testid="notification-whatsapp-details">
      <div className="mt-8 text-base font-bold text-text-primary">
        {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SECTION_TITLE'})}
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_TYPE'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{notificationTypeName}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.TEMPLATE_SELECTED'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{whatsAppMessage}</div>
      </div>
    </div>
  )
}
