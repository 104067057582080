import {
  WhatsappTemplateCategoryCell,
  WhatsappTemplateCreatedByCell,
  WhatsappTemplateNameCell,
  WhatsappTemplateStatusCell,
  WhatsappTemplateTemplateUsageCell,
  WhatsappTemplateUpdatedOnCell,
} from '@/components/MessageTemplateTable/WhatsappTemplateCell'
import {DataTableColumnHeader} from '@/shared-components/DataTable/DataTableColumnHeader'
import {WhatsappTemplateListItem} from '@/types/WhatsappTemplate'
import {ColumnDef} from '@tanstack/react-table'
import {CSSProperties} from 'react'
import {IntlFormatters} from 'react-intl'

export type CustomColumnMeta = {
  styles?: CSSProperties
}

export const getWhatsAppTemplateColumn = (
  formatMessage: IntlFormatters['formatMessage'],
  onWhatsAppTemplateNameClick: (templateId: string) => void
): ColumnDef<WhatsappTemplateListItem, CustomColumnMeta>[] => {
  return [
    {
      accessorKey: 'name',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'WHATSAPP_TEMPLATE_LIST.TABLE_NAME_COLUMN'})} />
      ),
      cell: ({row}) => (
        <WhatsappTemplateNameCell
          name={row.getValue('name')}
          onClick={() => onWhatsAppTemplateNameClick(row.original.id)}
        />
      ),
      meta: {
        styles: {
          minWidth: 300,
        },
      },
    },
    {
      accessorKey: 'category',
      header: ({column}) => (
        <DataTableColumnHeader
          {...column}
          title={formatMessage({id: 'WHATSAPP_TEMPLATE_LIST.TABLE_CATEGORY_COLUMN'})}
        />
      ),
      cell: ({row}) => <WhatsappTemplateCategoryCell category={row.getValue('category')} />,
      meta: {
        styles: {
          minWidth: 200,
        },
      },
    },
    {
      accessorKey: 'status',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'WHATSAPP_TEMPLATE_LIST.TABLE_STATUS_COLUMN'})} />
      ),
      cell: ({row}) => <WhatsappTemplateStatusCell status={row.getValue('status')} />,
      meta: {
        styles: {
          minWidth: 200,
        },
      },
    },
    {
      accessorKey: 'updatedOn',
      header: ({column}) => (
        <DataTableColumnHeader
          {...column}
          title={formatMessage({id: 'WHATSAPP_TEMPLATE_LIST.TABLE_UPDATED_ON_COLUMN'})}
        />
      ),
      cell: ({row}) => <WhatsappTemplateUpdatedOnCell updatedOn={row.getValue('updatedOn')} />,
      meta: {
        styles: {
          minWidth: 200,
        },
      },
    },
    {
      accessorKey: 'createdBy',
      header: ({column}) => (
        <DataTableColumnHeader
          {...column}
          title={formatMessage({id: 'WHATSAPP_TEMPLATE_LIST.TABLE_CREATED_BY_COLUMN'})}
        />
      ),
      cell: ({row}) => <WhatsappTemplateCreatedByCell createdBy={row.getValue('createdBy')} />,
      meta: {
        styles: {
          minWidth: 200,
        },
      },
    },
    {
      accessorKey: 'templateUsage',
      header: ({column}) => (
        <DataTableColumnHeader
          {...column}
          title={formatMessage({id: 'WHATSAPP_TEMPLATE_LIST.TABLE_TEMPLATE_USAGE_COLUMN'})}
        />
      ),
      cell: ({row}) => <WhatsappTemplateTemplateUsageCell isActive={row.getValue('templateUsage')} />,
      meta: {
        styles: {
          minWidth: 200,
        },
      },
    },
  ]
}
