import {useGetWhatsAppTemplateDetails} from '@/components/Drawer/hooks/useWhatsAppTemplateDetails'
import WhatsAppTemplateButtonGroup from '@/components/Drawer/WhatsAppTemplateButtonGroup'
import WhatsAppTemplateMobilePreview from '@/components/Drawer/WhatsAppTemplateMobilePreview'
import {ROUTES} from '@/config/constants'
import {WhatsAppTemplateDrawerStatusEnum} from '@/types/WhatsAppTemplateDrawerStatusEnum'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'

interface Props {
  updateDrawerStatus: (value: WhatsAppTemplateDrawerStatusEnum) => void
}

export default function WhatsAppTemplateDetails({updateDrawerStatus}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  const {id: whatsAppTemplateId} = useParams()
  const navigate = useNavigate()

  const {data: templateDetailsData} = useGetWhatsAppTemplateDetails(whatsAppTemplateId)

  const onEditTemplate = () => {
    navigate(`${ROUTES.MESSAGE_TEMPLATE_EDIT.replace(':id', whatsAppTemplateId || '')}`)
    updateDrawerStatus(WhatsAppTemplateDrawerStatusEnum.createWhatsAppTemplate)
  }

  return (
    <div data-testid="whatsapp-template-details" className="ml-4 flex w-full flex-col items-start">
      <div className="mb-4 text-lg font-bold text-text-primary">{templateDetailsData?.templateName}</div>
      <WhatsAppTemplateMobilePreview templateMessage={templateDetailsData.templateMessage} />
      <WhatsAppTemplateButtonGroup
        testIdPrimary="whatsapp-template-edit"
        onClickPrimary={onEditTemplate}
        primaryButtonLabel={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.EDIT_BUTTON_LABEL'})}
      />
    </div>
  )
}
