import {notificationTypeConfig} from '@/config/EventTriggerConfig'
import {NotificationTypeEnum} from '@/types/NotificationTypeEnum'

export const isSendPushStep = (notificationType: string) => {
  return notificationTypeConfig.find(item => item.id === notificationType)?.type === NotificationTypeEnum.sendPush
}

export const isSendSmsStep = (notificationType: string) => {
  return notificationTypeConfig.find(item => item.id === notificationType)?.type === NotificationTypeEnum.sendSMS
}

export const isSendWhatsAppStep = (notificationType: string) => {
  return notificationTypeConfig.find(item => item.id === notificationType)?.type === NotificationTypeEnum.sendWhatsApp
}
