import {format, parseISO} from 'date-fns'
import {Link, Toggle} from 'pepsico-ds'
import {useState} from 'react'

interface WhatsappTemplateNameCellProps {
  name: string
  onClick: () => void
}

export const WhatsappTemplateNameCell = ({name, onClick}: WhatsappTemplateNameCellProps) => {
  return (
    <Link data-testid="whatsapp-template-name-cell" onClick={onClick}>
      {name}
    </Link>
  )
}

export const WhatsappTemplateCategoryCell = ({category}: {category: string}) => {
  return <div data-testid="whatsapp-template-category-cell">{category}</div>
}

export const WhatsappTemplateStatusCell = ({status}: {status: string}) => {
  return <div data-testid="whatsapp-template-status-cell">{status}</div>
}

export const WhatsappTemplateUpdatedOnCell = ({updatedOn}: {updatedOn: string}) => {
  const formatedTime = updatedOn === null ? '' : format(parseISO(updatedOn), 'MM/dd/yyyy')
  return (
    <div data-testid="updated-on-cell" className="text-sm">
      {formatedTime}
    </div>
  )
}

export const WhatsappTemplateCreatedByCell = ({createdBy}: {createdBy: string}) => {
  return (
    <div data-testid="created-by-cell" className="text-sm">
      {createdBy}
    </div>
  )
}

export const WhatsappTemplateTemplateUsageCell = ({isActive}: {isActive: boolean}) => {
  const [active, setActive] = useState<boolean>(isActive)

  return (
    <div data-testid="template-usage-cell">
      <Toggle
        checked={active}
        onUpdate={() => {
          setActive(!active)
        }}
        size="medium"
        value={active ? 'active' : 'inactive'}
      />
    </div>
  )
}
