import CreateWhatsAppTemplatePage from '@/components/Drawer/CreateWhatsAppTemplatePage'
import {Drawer} from '@/components/Drawer/Drawer'
import WhatsAppTemplateDetails from '@/components/Drawer/WhatsAppTemplateDetails'
import WhatsAppTemplatePreview from '@/components/Drawer/WhatsAppTemplatePreview'
import {useCountries} from '@/hooks/useCountries'
import {WhatsAppTemplateDrawerStatusEnum} from '@/types/WhatsAppTemplateDrawerStatusEnum'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {ROUTES} from '../../config/constants'

interface Props {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  isTemplateDetailPage: boolean
}

export default function WhatsAppTemplateCreationDrawer({
  isOpen,
  setIsOpen,
  isTemplateDetailPage,
}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  const {country} = useCountries()
  const navigate = useNavigate()

  const [templateName, setTemplateName] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [templateMessage, setTemplateMessage] = useState<string>('')
  const [drawerStatus, setDrawerStatus] = useState<WhatsAppTemplateDrawerStatusEnum>(
    WhatsAppTemplateDrawerStatusEnum.createWhatsAppTemplate
  )

  const cleanUpAllFields = function (): void {
    setTemplateName('')
    setCategory('')
    setTemplateMessage('')
    setDrawerStatus(WhatsAppTemplateDrawerStatusEnum.createWhatsAppTemplate)
  }

  const closeDrawer = function (): void {
    cleanUpAllFields()
    navigate(`${ROUTES.MESSAGE_TEMPLATES}`)
    setIsOpen(false)
  }

  const persisData = async function (templateStatus: string) {
    // implement when the API is ready
    console.log('templateStatus', templateStatus)
  }

  const sendWhatsAppTemplate = async function () {
    persisData('Published')
    closeDrawer()
  }

  const sendWhatsAppTemplateDraft = async function () {
    persisData('Draft')
    closeDrawer()
  }

  const title = formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.CREATE_WHATSAPP_TEMPLATE_HEADER'})

  useEffect(() => {
    setDrawerStatus(
      isTemplateDetailPage
        ? WhatsAppTemplateDrawerStatusEnum.templateDetails
        : WhatsAppTemplateDrawerStatusEnum.createWhatsAppTemplate
    )
  }, [isTemplateDetailPage])

  const drawStatusProvider = function (drawerStatus: WhatsAppTemplateDrawerStatusEnum): React.JSX.Element {
    const commonProps = {
      templateName,
      category,
      templateMessage,
    }

    const updateFieldProps = {
      setTemplateName: (value: string) => {
        setTemplateName(value)
      },
      setCategory: (value: string) => {
        setCategory(value)
      },
      setTemplateMessage: (value: string) => {
        setTemplateMessage(value)
      },
    }
    switch (drawerStatus) {
      case WhatsAppTemplateDrawerStatusEnum.createWhatsAppTemplate:
        return (
          <CreateWhatsAppTemplatePage
            {...commonProps}
            {...updateFieldProps}
            country={country}
            updateDrawerStatus={(status: WhatsAppTemplateDrawerStatusEnum) => setDrawerStatus(status)}
            closeDrawer={closeDrawer}
          />
        )
      case WhatsAppTemplateDrawerStatusEnum.preview:
        return (
          <WhatsAppTemplatePreview
            {...commonProps}
            sendWhatsAppTemplateDraft={sendWhatsAppTemplateDraft}
            sendWhatsAppTemplate={sendWhatsAppTemplate}
          />
        )
      case WhatsAppTemplateDrawerStatusEnum.templateDetails:
        return (
          <WhatsAppTemplateDetails
            updateDrawerStatus={(status: WhatsAppTemplateDrawerStatusEnum) => setDrawerStatus(status)}
          />
        )
    }
  }

  return (
    <Drawer isOpen={isOpen} headerTitle={title} onClose={closeDrawer} direction="right" size="410px">
      <div>{drawStatusProvider(drawerStatus)}</div>
    </Drawer>
  )
}
