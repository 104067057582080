import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {IIconProps, Icon} from 'pepsico-ds'
import {ReactNode, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {ROUTES} from '../../config/constants'

type MenuItem = {
  path: string
  label: string
  icon: (props: IIconProps) => ReactNode
  enabled: () => boolean
}

export const SidebarMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const currentPagePath = useLocation().pathname

  const toggleMenu = () => {
    setIsOpen(state => !state)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const menuItems: MenuItem[] = [
    {
      path: ROUTES.LIST_JOURNEYS,
      label: 'Journeys',
      icon: props => <Icon data-testid="menu-journeys-list" icon={'dashboard'} {...props} />,
      enabled: () => true,
    },
    {
      path: ROUTES.REPORTS,
      label: 'Reports',
      icon: props => <Icon data-testid="menu-reports" icon={'analytics'} {...props} />,
      enabled: () => false,
    },
    {
      path: ROUTES.EVENT_NOTIFICATIONS,
      label: 'Events',
      icon: props => <Icon data-testid="menu-events" icon={'account_tree'} {...props} />,
      enabled: () => isFeatureEnabled(FeatureToggle.realTimeNotificationPage),
    },
    {
      path: ROUTES.MESSAGE_TEMPLATES,
      label: 'Message Templates',
      icon: props => <Icon data-testid="menu-message-templates" icon={'present_to_all'} {...props} />,
      enabled: () => isFeatureEnabled(FeatureToggle.messageTemplates),
    },
    {
      path: ROUTES.SETTINGS,
      label: 'Settings',
      icon: props => <Icon data-testid="menu-settings" icon={'settings'} {...props} />,
      enabled: () => true,
    },
  ]

  return (
    <>
      <nav
        data-testid="side-menu"
        className={`shadow-lg absolute z-10 flex h-full flex-col bg-layer-01 ${isOpen ? 'w-64' : 'w-20'}`}
      >
        {!isOpen && (
          <div
            className="mb-4 flex h-14 w-full cursor-pointer items-center pl-7"
            title="Open menu"
            onClick={toggleMenu}
          >
            <Icon icon={'menu'} className="text-text-primary hover:text-text-black" />
          </div>
        )}
        {isOpen && (
          <div className="mb-4 flex h-14 w-full items-center justify-between pl-7 pr-4" title="Close menu">
            <span className="cursor-pointer text-text-primary hover:text-text-black" onClick={closeMenu}>
              Close
            </span>
            <Icon
              icon={'close'}
              size="medium"
              className="cursor-pointer text-text-primary hover:text-text-black"
              onClick={closeMenu}
            />
          </div>
        )}

        <ul className="flex h-full w-full flex-col gap-4">
          {menuItems
            .filter(item => item.enabled())
            .map((item, idx) => {
              const isSelected = currentPagePath === item.path
              const Icon = item.icon

              return (
                <li
                  key={`${idx}_${item.path}`}
                  className={`flex ${
                    isSelected
                      ? 'to-none bg-gradient-to-r from-link from-[5px] to-[5px] text-link hover:bg-layer-01-hover'
                      : 'text-text-secondary hover:bg-layer-02'
                  }`}
                >
                  <Link to={item.path} className={`flex w-full cursor-pointer gap-4 py-2 pl-8`} onClick={closeMenu}>
                    <span data-cy={`${item.label}-sidebar-option`} className="flex w-6 justify-center">
                      <Icon
                        className={
                          isSelected ? `text-link hover:text-link-hover` : `text-text-primary hover:text-text-black`
                        }
                      />
                    </span>
                    <span className={`${isOpen ? 'flex' : 'hidden'} flex-1`}> {item.label}</span>
                  </Link>
                </li>
              )
            })}
        </ul>
      </nav>
      {isOpen && <div className="absolute z-[9] h-screen w-screen cursor-default bg-gray-400/60" onClick={closeMenu} />}
    </>
  )
}
