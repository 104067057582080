import {useGetWhatsAppTemplateDetails} from '@/components/Drawer/hooks/useWhatsAppTemplateDetails'
import WhatsAppTemplateButtonGroup from '@/components/Drawer/WhatsAppTemplateButtonGroup'
import {AutoComplete} from '@/components/SendNotification/AutoComplete'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {InputText} from '@/shared-components/InputText'
import {WhatsAppTemplateDrawerStatusEnum} from '@/types/WhatsAppTemplateDrawerStatusEnum'
import {MAX_MESSAGE_LENGTH} from '@/utils/consts'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'

interface Props {
  templateName: string
  setTemplateName: (value: string) => void
  category: string
  setCategory: (value: string) => void
  templateMessage: string
  setTemplateMessage: (value: string) => void
  country: string
  updateDrawerStatus: (value: WhatsAppTemplateDrawerStatusEnum) => void
  closeDrawer: () => void
}

export default function CreateWhatsAppTemplatePage({
  country,
  templateName,
  setTemplateName,
  category,
  setCategory,
  templateMessage,
  setTemplateMessage,
  updateDrawerStatus,
  closeDrawer,
}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()

  const [isAllFieldsValid, setIsAllFieldsValid] = useState<boolean>(false)

  const {pathname} = useLocation()
  const isEditingTemplate = pathname?.includes('/edit')

  const {data: templateDetailsData, isFetching, error} = useGetWhatsAppTemplateDetails()

  useEffect(() => {
    if (isEditingTemplate && !isFetching && !error) {
      setTemplateName(templateDetailsData?.templateName)
      setCategory(templateDetailsData?.category)
      setTemplateMessage(templateDetailsData?.templateMessage)
    }
  }, [isEditingTemplate, templateDetailsData, setTemplateName, setCategory, setTemplateMessage, isFetching, error])

  const categoryOptions = [
    {id: 1, value: 'OTP'},
    {id: 2, value: 'Offer'},
    {id: 3, value: 'New flaver'},
    {id: 4, value: 'Order remainder'},
  ].map(item => {
    return (
      <option key={item.id} value={item.value}>
        {item.value}
      </option>
    )
  })

  useEffect(() => {
    const isReqiredFieldsFilled = templateName && category && templateMessage ? true : false
    setIsAllFieldsValid(isReqiredFieldsFilled)
  }, [templateName, category, templateMessage])

  return (
    <div className="ml-2 mt-1 overflow-auto px-1" data-testid="create-whatsapp-template-container">
      <div className="mb-4 text-lg font-bold text-text-primary">
        {formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.CREATE_WHATSAPP_TEMPLATE_HEADER'})}
      </div>
      <div className="flex flex-col pb-6 text-text-secondary">
        <p className="text-sm font-normal">
          {formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.CREATE_WHATSAPP_TEMPLATE_SUBHEADER'})}
        </p>
      </div>
      <div className="h-42 flex w-full max-w-xs flex-col gap-1">
        <InputSelector
          data-testid="country"
          value={country}
          label={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.COUNTRY_LABEL'})}
          required
          disabled
        >
          <option value={country}>{country}</option>
        </InputSelector>
      </div>
      <div className="h-42 mt-3 flex w-full max-w-xs flex-col gap-1">
        <InputText
          label={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.TEMPLATE_NAME_LABEL'})}
          placeholder={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.TEMPLATE_NAME_PLACEHOLDER'})}
          onChange={evt => {
            setTemplateName(evt.target.value)
          }}
          value={templateName}
          maxLength={100}
          id="whatsapp-template-name"
          required
        />
      </div>
      <div className="h-42 mt-3 flex w-full max-w-xs flex-col gap-1">
        <InputSelector
          id="whatsapp-template-category"
          value={category}
          onChange={evt => setCategory(evt.target.value)}
          label={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.CATEGORY_LABEL'})}
          required
        >
          <option value="">{formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.TEMPLATE_CATEGORY_PLACEHOLDER'})}</option>
          {categoryOptions}
        </InputSelector>
      </div>
      <div className="h-42 mt-3 flex w-full max-w-xs flex-col gap-1">
        <AutoComplete
          label={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.MESSAGE_LABEL'})}
          inputId="whatsapp-template-message"
          inputValue={templateMessage}
          onChangeInput={setTemplateMessage}
          max_message_length={MAX_MESSAGE_LENGTH}
          required
        />
      </div>
      <WhatsAppTemplateButtonGroup
        testIdTertiary="create-whatsapp-template-cancel"
        testIdPrimary="whatsapp-template-preview"
        onClickPrimary={() => {
          updateDrawerStatus(WhatsAppTemplateDrawerStatusEnum.preview)
        }}
        onClickTertiary={closeDrawer}
        primaryButtonLabel={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.PREVIEW_BUTTON_LABEL'})}
        tertiaryButtonLabel={formatMessage({id: 'WHATSAPP_TEMPLATE_BUILDER.CANCEL_BUTTON_LABEL'})}
        isPrimaryDisabled={!isAllFieldsValid}
      />
    </div>
  )
}
