import {DateRangeSelector} from '@/components/Drawer/ContentParams/DateRangeSelector'
import SendPushParams from '@/components/Drawer/ContentParams/RealtimeNotificationStepConfig/SendPushParams'
import SendSmsParams from '@/components/Drawer/ContentParams/RealtimeNotificationStepConfig/SendSmsParams'
import SendWhatsAppParams from '@/components/Drawer/ContentParams/RealtimeNotificationStepConfig/SendWhatsAppParams'
import {StepConfigLoader} from '@/components/Drawer/ContentParams/StepConfigLoader/StepConfigLoader'
import DrawerNotificationButtonGroups from '@/components/Drawer/DrawNotificationButtonGroups'
import {NotificationDetailsProps} from '@/components/Drawer/NotificationDetails'
import NotificationEventCard from '@/components/NotificationParamsFilterCard/NotificationEventCard'
import {defaultEventValue} from '@/components/NotificationParamsFilterCard/NotificationEventDefaultValue'
import NotificationParamCard from '@/components/NotificationParamsFilterCard/NotificationParamCard'
import {STEP_CONFIG} from '@/config/constants'
import {
  fallsOnBankHolidayConfig,
  fallsOnBankHolidayType,
  notificationTypeConfig,
  NotificationTypeType,
} from '@/config/EventTriggerConfig'
import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {isSendPushStep, isSendSmsStep, isSendWhatsAppStep} from '@/helpers/rtnNotificationType'
import {useTimezone} from '@/hooks/useTimezone'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {InputText} from '@/shared-components/InputText'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {TimeSelector} from '@/shared-components/TimeSelector'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {NotificationDrawerStatusEnum} from '@/types/NotificationDrawerStatusEnum'
import {Period} from '@/types/Period'
import {GetSendPushStepConfigResponse} from '@/types/StepConfigParams'
import {TimeExecutionWindowNotification} from '@/types/TimeExecution'
import {OperatorsType, ParameterItem, Parameters, TraitsType} from '@/types/Traits'
import {useQueryClient} from '@tanstack/react-query'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

export interface CreateNotificationPageProps extends NotificationDetailsProps {
  readonly setNotificationName: (value?: string | ((prevValue?: string) => string)) => void
  readonly setNotificationDescription: (value: string | ((prevValue?: string) => string)) => void
  readonly country: string
  readonly events: TraitsType
  readonly eventOperators: OperatorsType
  readonly traits: TraitsType
  readonly operators: OperatorsType
  readonly eventSelectors?: ParameterItem
  readonly paramSelectors?: Parameters
  readonly setEventSelectors: (value: ParameterItem | ((prevValue: ParameterItem) => ParameterItem)) => void
  readonly setParamSelectors: (value: Parameters | ((prevValue: Parameters) => Parameters)) => void
  readonly setPeriod: (value?: Period | ((prevValue?: Period) => Period)) => void
  readonly setTimeExecutionWindow: (
    value?:
      | TimeExecutionWindowNotification
      | ((prevValue?: TimeExecutionWindowNotification) => TimeExecutionWindowNotification)
  ) => void
  readonly setFallsOnBankHoliday: (value?: string | ((prevValue?: string) => string)) => void

  readonly notificationType?: string
  readonly setNotificationType: (value?: string | ((prevValue?: string) => string)) => void
  readonly subject?: string
  readonly setSubject: (value?: string | ((prevValue?: string) => string)) => void
  readonly message?: string
  readonly setMessage: (value?: string | ((prevValue?: string) => string)) => void
  readonly smsMessage?: string
  readonly setSmsMessage: (value?: string | ((prevValue?: string) => string)) => void
  readonly selectedTemplateId?: string
  readonly setSelectedTemplateId: (value?: string | ((prevValue?: string) => string)) => void
  readonly whatsAppMessage?: string
  readonly setWhatsAppMessage: (value?: string | ((prevValue?: string) => string)) => void

  readonly updateDrawStatus: (status: NotificationDrawerStatusEnum) => void
  readonly isRemoteFetch: boolean
  readonly closeDrawer: () => void
  readonly saveAsDraftAndCloseDrawer: () => void
}

export default function CreateNotificationPage({
  notificationName = '',
  setNotificationName,
  notificationDescription = '',
  setNotificationDescription,
  country,
  events,
  eventOperators,
  traits,
  operators,
  eventSelectors = defaultEventValue,
  setEventSelectors,
  paramSelectors = [],
  setParamSelectors,

  period = {start: '', end: ''},
  setPeriod,
  timeExecutionWindow = {start: '', end: ''},
  setTimeExecutionWindow,
  fallsOnBankHoliday = '',
  setFallsOnBankHoliday,

  notificationType = '',
  setNotificationType,
  subject = '',
  setSubject,
  message = '',
  setMessage,

  smsMessage = '',
  setSmsMessage,
  selectedTemplateId = '',
  setSelectedTemplateId,
  whatsAppMessage = '',
  setWhatsAppMessage,

  updateDrawStatus,
  isRemoteFetch,
  closeDrawer,
  saveAsDraftAndCloseDrawer,
}: CreateNotificationPageProps) {
  const {formatMessage} = useIntl()
  const [isAllFieldsValid, setIsAllFieldsValid] = useState<boolean>(false)
  const {tz} = useTimezone()

  const goToPreviewPage = function (): void {
    updateDrawStatus(NotificationDrawerStatusEnum.preview)
  }

  const isExtraConfigValid = (
    period: Period,
    timeExecutionWindow: TimeExecutionWindowNotification,
    fallsOnBankHoliday: string
  ) => {
    const isDurationEnabled = isFeatureEnabled(FeatureToggle.realTimeNotificationDuration)
    const hasDurationSet = !isDurationEnabled || period.start !== ''

    const isTimeWindowEnabled = isFeatureEnabled(FeatureToggle.realTimeNotificationTimeExecutionWindow)
    const hasTimeExecutionWindowSet =
      !isTimeWindowEnabled || (timeExecutionWindow.start !== '' && timeExecutionWindow.end !== '')
    const hasFallsOnBankHolidaySet = !isTimeWindowEnabled || fallsOnBankHoliday !== ''

    return hasDurationSet && hasTimeExecutionWindowSet && hasFallsOnBankHolidaySet
  }

  useEffect(() => {
    // Event Notification Meta
    const isNotificationNameValid = notificationName !== ''
    const isNotificationDescriptionValid = notificationDescription !== ''
    const isNotificationTypeValid = notificationType !== ''
    const isNotificationMetaValid = isNotificationNameValid && isNotificationDescriptionValid && isNotificationTypeValid

    const isEventValid =
      eventSelectors.trait.id !== -1 && eventSelectors.operator.id !== -1 && eventSelectors?.predefinedValue?.id !== -1

    let isParamsValid = true
    paramSelectors.some(param => {
      if (
        param.trait.id >= 0 &&
        param.operator.id >= 0 &&
        // if predefined value is selected, or user input value is not empty
        ((param?.predefinedValue?.id ?? -1) >= 0 || (param.value !== null && param?.value !== ''))
      ) {
        return false
      }
      isParamsValid = false
      return true
    })

    // Event Notification Extra config
    const isNotificationAdvancedParametersValid = isExtraConfigValid(period, timeExecutionWindow, fallsOnBankHoliday)

    // Notification is selected type fields valid
    let isNotificationTypeFieldsValid = true
    if (isSendPushStep(notificationType)) {
      isNotificationTypeFieldsValid = subject !== '' && message !== ''
    }

    if (isSendSmsStep(notificationType)) {
      isNotificationTypeFieldsValid = smsMessage !== ''
    }

    if (isSendWhatsAppStep(notificationType)) {
      isNotificationTypeFieldsValid = whatsAppMessage !== '' && selectedTemplateId !== ''
    }

    setIsAllFieldsValid(
      isNotificationMetaValid &&
        isNotificationAdvancedParametersValid &&
        isNotificationTypeFieldsValid &&
        isEventValid &&
        isParamsValid
    )
  }, [
    notificationName,
    notificationDescription,
    notificationType,
    period,
    timeExecutionWindow,
    fallsOnBankHoliday,
    subject,
    message,
    eventSelectors,
    paramSelectors,
    smsMessage,
    selectedTemplateId,
    whatsAppMessage,
  ])

  const notificationTypeOptions: React.JSX.Element[] = notificationTypeConfig
    .filter(item => item.isEnabled())
    .map((item: NotificationTypeType) => {
      return (
        <option value={item.id} key={item.type}>
          {formatMessage({id: item.name})}
        </option>
      )
    })

  const queryClient = useQueryClient()
  const getStepConfigData = {
    sendPush: () =>
      queryClient.getQueryData([STEP_CONFIG.DATA_QUERY_KEY, NodeTypeEnum.sendPush]) as GetSendPushStepConfigResponse,
  }

  const fallsOnBankHolidayOptions: React.JSX.Element[] = fallsOnBankHolidayConfig.map(
    (item: fallsOnBankHolidayType) => {
      return (
        <option value={item.name} key={item.name}>
          {formatMessage({id: item.name})}
        </option>
      )
    }
  )

  useEffect(() => {
    setNotificationName(notificationName)
    setNotificationDescription(notificationDescription)
    setParamSelectors(paramSelectors)
    setPeriod(period)
    setTimeExecutionWindow(timeExecutionWindow)
    setFallsOnBankHoliday(fallsOnBankHoliday)
    setNotificationType(notificationType)
    setSubject(subject)
    setMessage(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* <div className="col-span-4">Event Based Setting Placeholder for new layout</div> */}
      <div
        className="col-span-8 mb-16 flex grid-cols-subgrid flex-col gap-6"
        data-testid="create-new-notification-container"
      >
        <div className="border-b pb-4" style={{borderColor: '#DFDFDF'}}>
          <div className="flex flex-col pb-6 text-text-secondary">
            <p className="text-sm font-normal">{formatMessage({id: 'NOTIFICATION_BUILDER.EDITOR_SUBHEADER'})}</p>
          </div>
          <div className="items-left flex h-fit w-full max-w-[678px] flex-col gap-6 px-1">
            <div className="h-42 flex w-full max-w-xs flex-col gap-1">
              <InputText
                label={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_NAME_LABEL'})}
                placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_NAME_PLACEHOLDER'})}
                onChange={e => {
                  setNotificationName(e.target.value)
                }}
                value={notificationName}
                maxLength={100}
                id="notification-name"
                required
              />
            </div>
            <div className="h-42 flex w-full max-w-xs flex-col gap-1">
              <InputSelector
                data-testid="country"
                value={country}
                label={formatMessage({id: 'NOTIFICATION_BUILDER.COUNTRY_LABEL'})}
                required
                disabled
              >
                <option value={country}>{country}</option>
              </InputSelector>
            </div>
            <div className="h-42 flex w-full max-w-xs flex-col gap-1">
              <InputTextArea
                label={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_DESCRIPTION_LABEL'})}
                placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_NAME_PLACEHOLDER'})}
                onChange={e => {
                  setNotificationDescription(e.target.value)
                }}
                value={notificationDescription}
                id="notification-description"
                required
              />
            </div>
            <NotificationEventCard
              events={events}
              eventOperators={eventOperators}
              eventSelectors={eventSelectors}
              setEventSelectors={setEventSelectors}
            />
            <div>
              <NotificationParamCard
                traits={traits}
                operators={operators}
                paramSelectors={paramSelectors}
                setParamSelectors={setParamSelectors}
              />
            </div>
            {isFeatureEnabled(FeatureToggle.realTimeNotificationDuration) && (
              <div data-testid="rtn-duration">
                <DateRangeSelector
                  timezone={tz}
                  start={period.start}
                  end={period?.start ? period?.end : undefined}
                  onChange={(start = '', end = '') => {
                    setPeriod({start, end})
                  }}
                  showInfo={false}
                  isStartDateRequired={true}
                />
              </div>
            )}
          </div>

          {isFeatureEnabled(FeatureToggle.realTimeNotificationTimeExecutionWindow) && (
            <div className="mt-4" data-testid="rtn-time-execution-window">
              <div className="flex flex-col gap-2 text-text-secondary">
                <p className="text-sm font-bold">
                  {formatMessage({id: 'NOTIFICATION_DETAILS.TIME_EXECUTION_WINDOW_TITLE'})}
                </p>
                <div
                  key={'rtn-time-selector'}
                  className="flex w-full items-center gap-7"
                  data-testid="time-execution-window-item"
                >
                  <div className="h-42 flex w-full max-w-xs flex-col gap-1">
                    <TimeSelector
                      className="flex-1"
                      label={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_START'})}
                      empty={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_SELECT_START'})}
                      id={`time-execution-window-start-1`}
                      value={timeExecutionWindow?.start}
                      onChange={evt => setTimeExecutionWindow({start: evt.target.value, end: timeExecutionWindow?.end})}
                      disabled={false}
                      isEndTime
                      required
                    />
                  </div>

                  <div className="flex w-full items-center gap-6">
                    <div className="h-42 flex w-full max-w-xs flex-col gap-1">
                      <TimeSelector
                        className="flex-1"
                        label={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_END'})}
                        empty={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_SELECT_END'})}
                        id={`time-execution-window-end-1`}
                        value={timeExecutionWindow?.end}
                        onChange={evt =>
                          setTimeExecutionWindow({start: timeExecutionWindow?.start ?? '', end: evt.target.value})
                        }
                        startTime={timeExecutionWindow?.start}
                        disabled={false}
                        isEndTime
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isFeatureEnabled(FeatureToggle.realTimeNotificationTimeExecutionWindow) && (
            <div className="mt-4 flex flex-col" data-testid="rtn-falls-on-bank-holiday">
              <div className="h-42 flex w-full max-w-xs flex-col gap-3">
                <InputSelector
                  data-testid="falls-on-bank-holiday-select"
                  onChange={evt => {
                    setFallsOnBankHoliday(evt.target.value)
                  }}
                  value={fallsOnBankHoliday}
                  label={formatMessage({id: 'NOTIFICATION_BUILDER.IF_FALLS_ON_BANK_HOLIDAY_LABEL'})}
                  id="falls-on-bank-holiday-select"
                  required
                >
                  <option value="">{formatMessage({id: 'NOTIFICATION_BUILDER.BANK_HOLIDAY_DEFAULT_OPTION'})}</option>
                  {fallsOnBankHolidayOptions}
                </InputSelector>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col text-text-secondary">
          <p className="text-sm font-normal">{formatMessage({id: 'NOTIFICATION_BUILDER.DETAILS_SUBHEADER'})}</p>
        </div>
        <div className="flex flex-col">
          <div className="h-42 flex w-full flex-col gap-3">
            <div className="max-w-xs">
              <InputSelector
                onChange={e => {
                  setNotificationType(e.target.value)
                }}
                value={notificationType}
                label={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_TYPE_LABEL'})}
                id="notification-type"
                required
              >
                <option value="">{formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_DEFAULT_OPTION'})}</option>
                {notificationTypeOptions}
              </InputSelector>
            </div>
            {isSendPushStep(notificationType) && (
              <StepConfigLoader type={NodeTypeEnum.sendPush} fetchFromRemote={isRemoteFetch}>
                <SendPushParams
                  getData={getStepConfigData.sendPush}
                  setSubject={setSubject}
                  setMessage={setMessage}
                  subject={subject}
                  message={message}
                />
              </StepConfigLoader>
            )}
            {isSendSmsStep(notificationType) && <SendSmsParams smsMessage={smsMessage} setSmsMessage={setSmsMessage} />}
            {isSendWhatsAppStep(notificationType) && (
              <SendWhatsAppParams
                selectedTemplateId={selectedTemplateId}
                setSelectedTemplateId={setSelectedTemplateId}
                whatsAppMessage={whatsAppMessage}
                setWhatsAppMessage={setWhatsAppMessage}
              />
            )}
          </div>
          <DrawerNotificationButtonGroups
            hasSecondaryButton={true}
            hasTertiaryButton={true}
            testIdPrimary="preview-button"
            testIdSecondary="save-as-draft-button"
            testIdTertiary="cancel-button"
            onClickPrimary={goToPreviewPage}
            onClickSecondary={saveAsDraftAndCloseDrawer}
            onClickTertiary={closeDrawer}
            primaryButtonLabel={formatMessage({
              id: 'NOTIFICATION_BUILDER.PREVIEW_BUTTON',
            })}
            secondaryButtonLabel={formatMessage({
              id: 'NOTIFICATION_BUILDER.SAVE_AS_DRAFT_BUTTON',
            })}
            tertiaryButtonLabel={formatMessage({
              id: 'NOTIFICATION_BUILDER.CANCEL_BUTTON',
            })}
            isPrimaryDisabled={!isAllFieldsValid}
            isSecondaryDisabled={!isAllFieldsValid}
          />
        </div>
      </div>
    </>
  )
}
